import React, { useState, useEffect } from 'react';
import { APIProvider, Map, useMap, AdvancedMarker, Polyline, Marker } from '@vis.gl/react-google-maps';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { Polygon } from './Polygon.tsx';
import { POLYGONS } from './encoded.ts';

export default function DroneMap() {

  const [zones, setZones] = useState(undefined);
  const navigate = useNavigate()
  const [polygons, setPolygons] = useState(null);
  const [defaultCenter, setDefaultCenter] = useState(null);
  const [points, setPoints] = useState(null);
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => refreshPoints(), 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // Update the document title using the browser API
    axios.get("/services/zones",
      {
        'headers': {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        try{
          normalizeZones(res.data);
        } catch (e) {
          console.log(e);
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          navigate("/login");
        }

      })
  }, []);

  useEffect(() => {
    refreshPoints();
  }, []);

  const refreshPoints = () => {
    // Update the document title using the browser API
    axios.get("/services/points",
      {
        'headers': {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        try{
          normalizePoints(res.data);
        } catch (e) {
          console.log(e);
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status == 401) {
          navigate("/login");
        }

      })
  }

  const normalizePoints = (data) => {
    let markers = [];
    console.log(data);
    data.forEach(m => {
      let row = { lat: Number(m[0]), lng: Number(m[1]) }
      markers.push(row);
    });
    console.log("markers");
    console.log(markers);
    setPoints(markers);
  }

  const normalizeZones = (data) => {
    let zones = [];
    let zone = [];
    data.forEach(element => {
      let array = element[0].replace("POLYGON((","").replace("))","");
      array = array.split(",");
      zone = [];
      array.forEach(e => {
        let coor = e.split(" ");
        coor = { lat: Number(coor[0]), lng: Number(coor[1]) }
        zone.push(coor);
      });
      let color, stroke;
      console.log(element[1]);
      if (element[1] == "friend") { // verde
        color = "rgba(1,1,1,1)";
        stroke = "#0F8C03";
      }
      if (element[1] == "warning") { // azul
        color = "#73A4F5";
        stroke = "#03378C";
      }
      if (element[1] == "alert") { // rojo
        color = "red";
        stroke = "#F53014";
      }
      if (element[1] == "neutral") { // blanco
        color = "white";
        stroke = "#F53014";
      }
      zones.push({ path: zone, fillColor: color, strokeColor: stroke });
    });
    setPolygons(zones);
    setDefaultCenter(zones[0].path[0])
  }



  const defaultProps = {
    lat: 10.99835602,
    lng: 77.01502627
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100vh', width: '100%' }}>
      <APIProvider apiKey={'AIzaSyCtVcbzEZJCQkDml1iKhhhqvUEpdGLAiSU'}>
        {polygons && points &&
          <Map defaultCenter={defaultCenter ? defaultCenter : defaultProps} defaultZoom={15}>
            {polygons.map((element) => {
              return <Polygon strokeWeight={2} encodedPaths={[element.path]} keyValue={1} fillColor={element.fillColor} strokeColor={element.strokeColor} />
            })}
            {points.map((element) => {
              console.log("Points" + element.lat);
              return <Marker position={element} />
            })}
          </Map>
        }
      </APIProvider>



    </div>
  );
}