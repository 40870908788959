import logo from '../logo.svg';
import DroneMap from '../components/DroneMap';

function Home() {
  return (
        <DroneMap />
  );
}

export default Home;
