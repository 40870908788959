import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './Login.css'
import axios from 'axios';

const Login = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
  
    const navigate = useNavigate()

    const onButtonClick = () => {
        axios.post("/services/token", {},{
            "headers": {
                "Content-Type": "application/json",
                "email": email,
                "password": password
            }
        })
        .then(res => {
            console.log(res);
            navigate("/");
        })
        .catch(error => {
            setEmailError("Usuario o contraseña inconrrecto")
            console.log(error);
        })
    }

    return (
        <div className={'mainContainer'}>
            <div className={'titleContainer'}>
                <div>Login</div>
            </div>
            <br />
            <div className={'inputContainer'}>
                <input
                    value={email}
                    placeholder="Ingrese su usuario o email"
                    onChange={(ev) => setEmail(ev.target.value)}
                    className={'inputBox'}
                />
                <label className="errorLabel">{emailError}</label>
            </div>
            <br />
            <div className={'inputContainer'}>
                <input
                    value={password}
                    type={'password'}
                    placeholder="Enter su contraseña"
                    onChange={(ev) => setPassword(ev.target.value)}
                    className={'inputBox'}
                />
                <label className="errorLabel">{passwordError}</label>
            </div>
            <br />
            <div className={'inputContainer'}>
                <input className={'inputButton'} type="button" onClick={onButtonClick} value={'Entrar'} />
            </div>
        </div>
    )
}

export default Login